<template>
  <v-app>
    <v-container fluid>
      <v-data-table
        :headers="headers"
        :items="authors"
        :page.sync="page"
        :server-items-length="meta.total"
        :items-per-page="meta.per_page"
        :loading="loading"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:top>
          <state-indicate ref="indicate" v-slot:default="{isLoading}">
            <v-toolbar
              flat
            >
              <v-toolbar-title>Authors List</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="editedIndex = -1"
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Author
                  </v-btn>
                </template>
                <ValidationObserver ref="observer" v-slot="{ invalid }">
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>

                          <v-col
                            cols="12"
                          >
                            <ValidationProvider name="First Name" vid="first_name" rules="required" v-slot="{ errors, valid }">
                              <v-text-field
                                v-model="editedItem.first_name"
                                :error-messages="errors"
                                :success="valid"
                                label="First Name"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>

                          <v-col
                            cols="12"
                          >
                            <ValidationProvider name="Surname" vid="surname" rules="required" v-slot="{ errors, valid }">
                              <v-text-field
                                v-model="editedItem.surname"
                                :error-messages="errors"
                                :success="valid"
                                label="Surname"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>

                          <v-col
                            cols="12"
                            v-if="editedIndex === -1"
                          >
                            <label for="checkbox">Register</label>&nbsp
                            <input type="checkbox" id="checkbox" value="1" v-model="register">
                          </v-col>

                          <template v-if="editedIndex !== -1 || register">
                            <v-col
                              cols="12"
                            >
                              <ValidationProvider name="Email" vid="email" rules="required|email" v-slot="{ errors, valid }">
                                <v-text-field
                                  v-model="editedItem.email"
                                  :error-messages="errors"
                                  :success="valid"
                                  label="Email"
                                  :disabled="editedIndex > -1"
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col
                              cols="12"
                            >
                              <ValidationProvider name="Password" vid="password" rules="required" v-slot="{ errors, valid }">
                                <v-text-field
                                  v-model="editedIndex > -1 ? '***********' : editedItem.password"
                                  :error-messages="errors"
                                  label="Password"
                                  :disabled="editedIndex > -1"
                                ></v-text-field>
                              </ValidationProvider>

                            </v-col>
                          </template>


                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                        :disabled="isLoading"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                        :disabled="invalid"
                        :loading="isLoading"
                      >
                        {{ editedIndex > -1 ? 'Save' : 'Create'}}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </ValidationObserver>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text :disabled="isLoading" @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text :loading="isLoading" @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </state-indicate>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn
            color="primary"
          >
            Reset
          </v-btn>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="meta.last_page"
        ></v-pagination>
      </div>
    </v-container>
  </v-app>
</template>

<script>
    import Author from '@/models/Author';
    import AuthorsApi from '@/api/authors';
    import StateIndicate from "../../../components/utils/StateIndicate";
    import {mapActions} from 'vuex';

    export default {
        name: "Index",
        data() {
            return {
                register: false,
                loading: false,
                authors: [],
                users: [],
                dialog: false,
                dialogDelete: false,
                editedIndex: -1,
                editedItem: {
                    id: '',
                    email: '',
                    password: '',
                    first_name: '',
                    surname: '',
                },
                defaultItem: {
                    email: '',
                    password: '',
                    first_name: '',
                    surname: '',
                },
                headers: [
                    {text: 'User id', value: 'user_id'},
                    {text: 'Email', value: 'email'},
                    {text: 'First Name', value: 'first_name'},
                    {text: 'Surname', value: 'surname'},
                    {text: 'Created at', value: 'created_at'},
                    {text: 'Actions', value: 'actions', sortable: false},
                ],
                meta: {},
                page: 1
            }
        },

        watch: {
            async page() {
                this.fetchAuthors()
            },
          dialog(val) {
            if (val && this.editedIndex === -1) {
              this.editedItem = Object.assign({}, this.defaultItem)
            }

            if (!val) {
              this.editedItem = Object.assign({}, this.defaultItem)
              this.editedIndex = -1;
              this.$nextTick(() => {
                this.$refs.observer.reset()
              });

            }
          }
        },

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'New Author' : 'Edit Author'
            },
        },

        async mounted() {
            this.fetchAuthors()
        },

        methods: {
            ...mapActions('auth', ['registration']),
            fetchUsers() {
                this.loading = true
                return UsersApi.list().then(ApiResponse => {
                    this.users = ApiResponse.getData();
                    this.loading = false
                });
            },
            fetchAuthors() {
                this.loading = true
                return Author.page(this.page).get().then(ApiResponse => {
                    this.authors = ApiResponse.getData();
                    this.meta = ApiResponse.getMeta();
                    this.loading = false
                });
            },
            editItem(item) {
                this.editedIndex = this.authors.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },

            deleteItem(item) {
                this.editedIndex = this.authors.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            async deleteItemConfirm() {
                this.$refs.indicate.isLoading = true
                try {
                    await AuthorsApi.delete(this.editedItem.id)
                    this.closeDelete()
                    this.fetchAuthors()
                } catch (e) {}
                this.$refs.indicate.isLoading = false
            },

            close() {
                this.dialog = false
            },

            closeDelete() {
                this.dialogDelete = false
                this.$nextTick(() => {
                    Object.assign(this.editedItem, this.defaultItem)
                    this.editedIndex = -1
                })
            },

            async save() {
                if (await this.$refs.observer.validate()) {
                    this.$refs.indicate.isLoading = true;
                    if (this.editedIndex > -1) {
                        try {
                            await AuthorsApi.update(this.editedItem.id, this.editedItem);
                            Object.assign(this.authors[this.editedIndex], this.editedItem);
                            this.close();
                        } catch (ApiResponse) {
                          if (ApiResponse.hasValidationErrors()) {
                            this.$refs.observer.setErrors(ApiResponse.getValidationErrors());
                          } else {
                            alert('Error while for updating Author');
                          }
                        }
                    } else {
                      try {
                        await AuthorsApi.store({...this.editedItem, register: this.register});
                        this.close();
                        this.fetchAuthors()
                      } catch (ApiResponse) {
                        if (ApiResponse.hasValidationErrors()) {
                          this.$refs.observer.setErrors(ApiResponse.getValidationErrors());
                        } else {
                          alert('Error while for creating Author');
                        }
                      }
                    }
                    this.$refs.indicate.isLoading = false;
                }
            }
        },

        components: {
            StateIndicate
        }
    }
</script>

<style scoped>

</style>
