var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.authors,"page":_vm.page,"server-items-length":_vm.meta.total,"items-per-page":_vm.meta.per_page,"loading":_vm.loading,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('state-indicate',{ref:"indicate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Authors List")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.editedIndex = -1}}},'v-btn',attrs,false),on),[_vm._v(" New Author ")])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"First Name","vid":"first_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"First Name"},model:{value:(_vm.editedItem.first_name),callback:function ($$v) {_vm.$set(_vm.editedItem, "first_name", $$v)},expression:"editedItem.first_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Surname","vid":"surname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"Surname"},model:{value:(_vm.editedItem.surname),callback:function ($$v) {_vm.$set(_vm.editedItem, "surname", $$v)},expression:"editedItem.surname"}})]}}],null,true)})],1),(_vm.editedIndex === -1)?_c('v-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"checkbox"}},[_vm._v("Register")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.register),expression:"register"}],attrs:{"type":"checkbox","id":"checkbox","value":"1"},domProps:{"checked":Array.isArray(_vm.register)?_vm._i(_vm.register,"1")>-1:(_vm.register)},on:{"change":function($event){var $$a=_vm.register,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="1",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.register=$$a.concat([$$v]))}else{$$i>-1&&(_vm.register=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.register=$$c}}}})]):_vm._e(),(_vm.editedIndex !== -1 || _vm.register)?[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"label":"Email","disabled":_vm.editedIndex > -1},model:{value:(_vm.editedItem.email),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"editedItem.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Password","disabled":_vm.editedIndex > -1},model:{value:(_vm.editedIndex > -1 ? '***********' : _vm.editedItem.password),callback:function ($$v) {_vm.$set(_vm.editedIndex > -1 ? '***********' : _vm.editedItem, "password", $$v)},expression:"editedIndex > -1 ? '***********' : editedItem.password"}})]}}],null,true)})],1)]:_vm._e()],2)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":isLoading},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":invalid,"loading":isLoading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.editedIndex > -1 ? 'Save' : 'Create')+" ")])],1)],1)]}}],null,true)})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":isLoading},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":isLoading},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]}}])})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" Reset ")])]},proxy:true}])}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.meta.last_page},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }